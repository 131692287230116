import type { User } from '@medsimples/doctor-onboarding-openapi-v2';
import * as SentryLib from '@sentry/nextjs';

export const Sentry = SentryLib;

export function reportSentryError(
  err: unknown,
  step?: string,
  user?: User,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  extraTags?: Record<string, string | number | boolean | Record<string, any>>,
) {
  try {
    SentryLib.captureException(err, {
      tags: {
        'onboarding.step': step || 'none',
        'user.id': user?.id,
        'user.email': user?.email,
        ...(extraTags ?? {}),
      },
    });
  } catch (err) {
    // avoid the error tracing raising more errors
    console.error('Error sending sentry report');
    console.error(err);
  }
}

export async function sentryCatch(
  fn: () => unknown,
  {
    step,
    extraTags,
    user,
  }: {
    step?: string;
    extraTags?: Record<string, string | number | boolean>;
    user: User;
  },
) {
  try {
    await fn();
  } catch (err) {
    reportSentryError(err, step, user, extraTags);
    throw err;
  }
}

export async function setUser(user: SentryLib.User) {
  try {
    Sentry.setUser(user);
  } catch {
    // avoid throwing sentry setup errors
  }
}
